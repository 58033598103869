.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark
    top: 12px

.bootstrap-select .dropdown-menu 
    border-radius: 0.375rem
    li a
        border-radius: 0.375rem
        margin-bottom: 3px

.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover, .dropdown-menu a:active, .dropdown-menu a:focus, .dropdown-menu a:hover
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%)