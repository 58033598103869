.builder
  box-shadow: inset 0 0 4px 0 rgba(150,150,150,.5)
  padding-left: 0.35rem
  padding-right: 0.35rem
  padding-top: 0.35rem
  min-height: 64px
  border-radius: 0.75rem
  overflow: hidden
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12
    padding-left: 0.15rem
    padding-right: 0.15rem

.builder__row
  margin-right: -0.15rem
  margin-left: -0.15rem
  min-height: 52px

.builder__item
  &__holder
    padding: 0.5rem 0.3rem
    margin-bottom: 0.3rem
    width: 100%
    text-transform: uppercase
    font-size: 10px
    color: #ffffff
    display: inline-block
    background-color: #C8C8C8
    font-weight: 500
    line-height: 1
    text-align: center
    vertical-align: baseline
    border-radius: 0.375rem
    cursor: grab

  &__select
    width: 25px
    margin: 0 0.2rem
    line-height: 1.5
    border: none
    border-radius: 0.1875rem
    padding: 0 0.2rem
    text-align: center
    appearance: none
  &__info
    display: block
    margin-top: 6px

.builder__item__holder.active
  background-color: #00b0ca