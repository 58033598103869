// all rules that dont have a place in other files

.bootstrap-datetimepicker-widget .timepicker
    .table-condesed .btn
        .ripple-container
            width: 40px
            height: 40px
            margin: -11px 3px

.form-check,
label
    font-size: 14px
    line-height: 1.42857
    color: #AAAAAA
    font-weight: 400

.copyright a
    color: #B6B6B6
    text-decoration: none
    &:hover
        text-decoration: none
        color: #00b0ca





// ripples in buttons
// marker class (used as a selector for one-off elements to decorate)
.ripple
    position: relative

.ripple-container
    position: absolute
    top: 0
    left: 0
    z-index: 1
    width: 100%
    height: 100%
    overflow: hidden
    pointer-events: none
    border-radius: inherit

    .ripple-decorator
        position: absolute
        width: 20px
        height: 20px
        margin-top: -10px
        margin-left: -10px
        pointer-events: none
        background-color: rgba($black, 0.05)
        border-radius: 100%
        opacity: 0
        transform: scale(1)
        transform-origin: 50%

        &.ripple-on
            opacity: 0.1
            transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s

        &.ripple-out
            opacity: 0
            transition: opacity 0.1s linear 0s !important

.animation-transition-fast
    @include transition-all($transition-time, ease 0s)

.title
    margin-top: 30px
    margin-bottom: 25px
    min-height: 32px

.disabled 
    pointer-events: none
    cursor: not-allowed

// multilevel dropdown
.dropdown 
    .dropdown-menu 
        .dropdown-item.dropdown-toggle + .dropdown-menu
            transform: scale(0)
            transform-origin: 0 0
            display: block

    .dropdown-menu.show 
        .dropdown-item.dropdown-toggle + .dropdown-menu.show 
            left: 101% !important
            transform: scale(1)

        .dropdown-toggle.open:after 
            transform: rotate(180deg)