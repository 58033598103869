.nav-tabs
    border: none
    padding: 0
    margin: 0
    .nav-item
        .nav-link
            color: $white
            border: none
            margin: 0
            border-radius: $crob-radius-small
            font-weight: bold
            font-size: 0.8rem
            margin-right: 0.5rem
            text-transform: uppercase
            background-color: transparent
            @include transition-all(.3s, ease)
            &:hover,
            &.active
                background-color: rgba($white, 0.2)