.card
    border: none
    background: $white
    width: 100%
    border-radius: $crob-radius-medium
    margin-bottom: 1.5rem
    @include shadow-2dp()

    .card-header,
    .card-body,
    .card-footer
        padding: 0.75rem
    
    .card-header
        border: none
        background: transparent
        position: relative
        .card-icon
            margin-right: 0.5rem
            border-radius: $crob-radius-small
            font-size: 1rem
            height: 2rem
            aspect-ratio: 1
            display: flex
            justify-content: center
            align-items: center
            color: $white
            float: left
        .card-title
            line-height: 1.77
            margin: 0
            i
                line-height: inherit
        .card-buttons
            position: absolute
            top: 0.75rem
            right: 0.75rem
            .btn
                padding: 0.7rem 1.2rem
                margin: 0
                i
                    margin-right: 5px

        &.card-header--tabs
            border-radius: 0.75rem 0.75rem 0 0
            margin-bottom: 0.75rem

        &.toggleAttributeGroup
            cursor: pointer
            .card-title i
                transition: all .3s ease
                &.down
                    transform: rotate(90deg)

    .card-body
        padding-top: 0