// Prevent highlight on mobile
* 
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
  -webkit-tap-highlight-color: transparent
  &:focus
    outline: 0
  
html * 
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  
body
  background-color: #f0f2f5
  // font-family: "noka", sans-serif
  font-family: "Roboto", sans-serif
  font-weight: 300
  // font-style: normal
  color: #000
  line-height: 1.5em

@include headings()
  // font-family: "noka", sans-serif
  font-family: "Roboto", sans-serif
  font-weight: 300
  line-height: 1.5em

h1, .h1 
  font-size: $font-size-h1
  line-height: 1.15em
h2, .h2
  font-size: $font-size-h2
h3, .h3
  font-size: $font-size-h3
  line-height: 1.4em
  margin: 20px 0 10px
h4, .h4
  font-size: $font-size-h4
  line-height: 1.4em
h5, .h5 
  font-size: $font-size-h5
  line-height: 1.4em
  margin-bottom: 15px
h6, .h6
  font-size: $font-size-h6
  text-transform: uppercase
  font-weight: $font-weight-bold

a
  color: $link-color
  text-decoration: none
  &:hover,
  &:focus
    color: darken($link-color, 5%)
    text-decoration: none

  &.text-info
    &:hover, &:focus
      color: darken($crob-blue, 5%)