.auth-page
  min-height: 100vh
  background-size: cover
  background-repeat: no-repeat
  background-position: center center
  background-blend-mode: multiply
  padding: 2rem
  display: flex
  justify-content: center
  align-items: center
  position: relative

  .auth-page-branding
    position: absolute
    bottom: 0
    left: 0
    right: 0
    display: flex
    justify-content: space-between
    align-items: center
    max-height: 75px
    height: 10vh
    @media (max-width: 700px)
      display: block
      height: auto
      max-height: none
    @media (max-height: 600px) and (max-width: 600px), (max-height: 600px) and (max-width: 1000px), (max-width: 300px)
      display: none
    &__logo
      height: 100%
      max-width: 100%
    &__url
      height: 50%
      margin-right: 2.5vh
      filter: invert(100%)
      @media (max-width: 700px)
        display: none

  .auth-form
    width: 100%
    height: 100%
    display: flex
    justify-content: center
    align-items: center

  .auth-card
    @extend .card
    max-width: 350px
    overflow: hidden
    
    .auth-card__header
      padding: 2rem 1.5rem
      display: flex
      justify-content: center
      align-items: center
      img
        max-height: 80px
        max-width: 100%

    .auth-card__body
      padding: 1.5rem 1.5rem 0.5rem 1.5rem
      display: flex
      flex-direction: column
      
    .auth-card__footer
      font-size: 0.7rem
      margin-bottom: 10px
      text-align: center
