.togglebutton
  label
    margin-top: 6px

.full-background
  background-size: cover
  background-repeat: no-repeat
  background-position: center center

.flex-grow-1
  flex-grow: 1

.img-raised
  box-shadow: 0 5px 15px -8px rgb(150 150 150 / 24%), 0 8px 5px -5px rgb(120 120 120 / 20%)

.img-thumbnail
  border-radius: 16px

.rounded
  border-radius: 6px !important
  
// .text-warning
//   color: $warning !important
// .text-primary
//   color: $primary !important
// .text-danger
//   color: $danger !important
// .text-success
//   color: $success !important
// .text-info
//   color: $crob-blue !important
// .text-rose
//   color: $crob-rose !important
// .text-gray
//   color: $crob-grey !important