.form-control
  background-color: transparent
  border: 0
  height: 36px
  padding-left: 0
  padding-right: 0
  border-radius: 0
  background: no-repeat bottom,center calc(100% - 1px)
  background-size: 0 100%, 100% 100%
  transition: background 0s ease-out
  &:focus
    background-size: 100% 100%, 100% 100%
    transition-duration: 0.3s
    box-shadow: none
    background-color: transparent
  &.is-invalid:focus
    box-shadow: none

  @include placeholder
    font-weight: 400
    opacity: .4


.form-control-feedback
  position: absolute
  top: 4px
  right: 0
  z-index: 2
  display: block
  width: 34px
  height: 34px
  line-height: 34px
  text-align: center
  pointer-events: none
  opacity: 0
  .has-success &
    color: $green
    opacity: 1
  .has-danger &
    color: $red
    opacity: 1



.form-group
  padding-bottom: 10px
  position: relative


textarea
  height: auto !important
  resize: none
  line-height: 1.42857143 !important


.form-group input[type=file]
  opacity: 0
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  width: 100%
  height: 100%
  z-index: -1


.form-file-upload
  .input-group-btn:last-child>.btn-round
    border-radius: 30px
  .input-group-btn .btn
    margin: 0
  .input-group
    width: 100%
  

.input-group .input-group-btn
  padding: 0 12px

.input-group
  .input-group-text
    display: flex
    justify-content: center
    align-items: center
    padding: 0 15px 0 15px
    background-color: transparent
    border-color: transparent


.form-control[disabled],
fieldset[disabled] .form-control,
.form-group .form-control[disabled],
fieldset[disabled] .form-group .form-control
  background-color: transparent
  cursor: not-allowed
  border-bottom: 1px dotted $input-border-color
  background-repeat: no-repeat





.form-text.has-danger
  color: #f44336

.filter-option-inner-inner
  color: rgb(73, 80, 87)

.required-star
  color: #f44336

.form-radio-group .btn input
  display: none

.image-thumbnail__image
  max-width: 250px
  overflow: hidden
  box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)
  border-radius: 4px
  img
    max-width: 100%

.modal-elfinder
  max-width: 85vw
  margin-top: 4vh !important

.modal-elfinder .modal-header
  padding-top: 35px

.modal-elfinder .modal-body
  padding-top: 10px

.filter-option-inner-inner
  font-size: 0.9rem

.form-control
  font-size: 1rem
  height: 41px

textarea.form-control
  border-left: 1px solid rgba(170, 170, 170, 0.5)
  border-right: 1px solid rgba(170, 170, 170, 0.5)
  border-top: 1px solid rgba(170, 170, 170, 0.5)
  padding: 10px

.input-group .input-group-text
  padding: 0 15px 0 0
  height: 100%

textarea.cke_source
  height: 100% !important  

.form-col
  margin-bottom: .4rem

.form-check .form-check-input
  opacity: 1
  height: auto
  width: auto
  overflow: visible
  z-index: 0
  margin: 0

.invalid-feedback
  display: block
  font-weight: 400
  color: #f44336

.is-invalid-label
  color: #f44336

