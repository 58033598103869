.btn:focus, 
.btn:active, 
.btn:hover
    box-shadow: 2px 2px 10px -5px rgb(0 0 0 / 75%)

.btn
    position: relative
    padding: 12px 24px
    margin: .3125rem 1px
    font-size: .75rem
    font-weight: 400
    line-height: 1.42857143
    text-decoration: none
    text-transform: uppercase
    letter-spacing: 0
    cursor: pointer
    background-color: transparent
    border: 0
    border-radius: 0.75rem
    outline: 0
    box-shadow: 2px 2px 5px -3px rgb(0 0 0 / 50%)
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)
    will-change: box-shadow, transform
    @include undo-bs-tab-focus()

    //--
    // Colors
    @include crob-raised-button-colors()
    &.btn-link:hover,
    &.btn-link:focus,
    &.btn-link:active
        text-decoration: none !important

    // Size variations
    &.btn-sm,
    .btn-group-sm &
        padding: .40625rem 1.25rem
        font-size: .6875rem
        line-height: $line-height-sm

    &.btn-round
        border-radius: 2rem

    &.btn-fab,
    &.btn-just-icon
        // see above for color variations
        font-size: 18px
        height: 35px
        min-width: 35px
        width: 35px
        padding: 0
        overflow: hidden
        position: relative
        line-height: 35px

        &.btn-round
            border-radius: 50%

        // .btn-group-sm &,
        // &.btn-sm,
        // &.btn-fab-mini
        //     height: $mdb-btn-fab-size-mini + 1
        //     min-width: $mdb-btn-fab-size-mini + 1
        //     width: $mdb-btn-fab-size-mini + 1

        //     .fa
        //         font-size: $mdb-btn-icon-size-mini
        //         line-height: $mdb-btn-fab-size-mini

        .fa 
            margin-top: 0
            position: absolute
            width: 100%
            transform: none
            left: 0
            top: 0
            height: 100%

            line-height: 41px
            font-size: 20px

.input-group-btn > .btn
    border: 0



// Align icons inside buttons with text
.btn:not(.btn-just-icon):not(.btn-fab) .fa
    position: relative
    display: inline-block
    top: 0
    margin-top: -1em
    margin-bottom: -1em
    font-size: 1.1rem
    vertical-align: middle

// btn-group variations
.btn-group
    position: relative
    margin: 10px 1px

    .dropdown-menu
        border-radius: 0 0 $border-radius $border-radius

    .btn + .btn,
    .btn,
    .btn:active,
    .btn-group
        margin: 0
