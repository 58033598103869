//
// Modals
// Material Design element Dialogs
// --------------------------------------------------

.modal .modal-dialog
    & .modal-content
        box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22)
        border: none
        border-radius: 0.75rem

    .close:focus 
        outline: none

    // Modal header
    // Top section of the modal w/ title and dismiss
    .modal-header
        border-bottom: none
        padding-top:    24px
        padding-right:  24px
        padding-bottom: 0
        padding-left:   24px

        .modal-title
            text-align: center
            width: 100%

        .close
            position: absolute
            top: 25px
            right: 30px
            padding: 0
            z-index: 10

    // Modal body
    // Where all modal content resides (sibling of .modal-header and .modal-footer)
    .modal-body
        padding-top:    24px
        padding-right:  24px
        padding-bottom: 16px
        padding-left:   24px


.modal-backdrop
    background: rgba(0,0,0,0.5)

.modal
    .modal-dialog
        margin-top: 100px

    .modal-header .close
        color: $crob-grey

        &:hover,
        &:focus
            opacity: 1

        i
            font-size: 16px