// from bs mixins/buttons button-variant
@mixin crob-button-variant($color, $background, $focus-background, $active-background, $border, $focus-border, $active-border) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover {
    color: $color;
    background-color: $focus-background;
    border-color: $focus-border;
  }

  &:focus,
  &.focus,
  &:hover{
    color: $color;
    background-color: $focus-background;
    border-color: $focus-border;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle,
  .show > &.dropdown-toggle {
    color: $color;
    background-color: $focus-background;
    border-color: $focus-border;
    @include shadow-2dp-color($background);

    &:hover,
    &:focus,
    &.focus {
      color: $color;
      background-color: $active-background;
      border-color: $active-border;
    }
  }

  // when it is an icon, kill the active bg on open dropdown, but stabilize on hover
  .open > &.dropdown-toggle.bmd-btn-icon {
    color: inherit;
    background-color: $background;

    // leave hover on with the lighter focus color
    &:hover {
      background-color: $focus-background;
    }
  }

  &.disabled,
  &:disabled {
    &:focus,
    &.focus {
      background-color: $background;
      border-color: $border;
    }
    &:hover {
      background-color: $background;
      border-color: $border;
    }
  }
}

@mixin bmd-flat-button-variant(
  $color,
  $border: $bmd-btn-border,
  $focus-border: $bmd-btn-focus-bg,
  $active-border: $bmd-btn-active-bg
) {
  $background: $bmd-btn-bg;
  $focus-background: $bmd-btn-focus-bg;
  $active-background: $bmd-btn-active-bg;

  @include crob-button-variant($color,
    $background,
    $focus-background,
    $active-background,
    $border,
    $focus-border,
    $active-border);

  // inverse color scheme
  .bg-inverse & {
    $focus-background: $bmd-inverse-btn-focus-bg;
    $focus-border: $bmd-inverse-btn-focus-bg;

    $active-background: $bmd-inverse-btn-active-bg;
    $active-border: $bmd-inverse-btn-active-bg;

    @include crob-button-variant($color,
      $background,
      $focus-background,
      $active-background,
      $border,
      $focus-border,
      $active-border);
  }

  // reverse the above for links
  &.btn-link {
    background-color: transparent;
  }
}

@mixin crob-raised-button-variant($color, $background, $border) {
  // FIXME: SPEC - this should be the 600 color, how can we get that programmatically if at all? Or are we limited to the color palette only?
  $focus-background: contrast-color(
    $background,
    darken($background, 3%),
    lighten($background, 3%)
  );
  //$focus-background: darken($background, 10%);  // default bootstrap
  $focus-border: darken($border, 12%);

  $active-background: $focus-background;
  //$active-background: darken($background, 17%);
  $active-border: darken($border, 25%);

  @include crob-button-variant($color,
    $background,
    $focus-background,
    $active-background,
    $border,
    $focus-border,
    $active-border);

    &.btn-link{
        background-color: transparent;
        color: $background;
        box-shadow: none;

        &:hover,
        &:focus,
        &:active{
            background-color: transparent;
            color: $background;
        }
    }

}

@mixin crob-raised-button-colors() {
  &.btn-primary {
    @include crob-raised-button-variant($white, $primary, $primary);
  }
  &.btn-secondary {
    @include crob-raised-button-variant($white, $secondary, $secondary);
  }
  &.btn-info {
    @include crob-raised-button-variant($white, $info, $info);
  }
  &.btn-success {
    @include crob-raised-button-variant($white, $success, $success);
  }
  &.btn-warning {
    @include crob-raised-button-variant($white, $warning, $warning);
  }
  &.btn-danger {
    @include crob-raised-button-variant($white, $danger, $danger);
  }
  &.btn-rose {
    @include crob-raised-button-variant($white, $crob-rose, $crob-rose);
  }
  &,
  &.btn-default{
    @include crob-raised-button-variant($white, $crob-blue, $crob-blue);
  }
}

@mixin undo-bs-tab-focus() {
  // clear out the tab-focus() from BS
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      //@include tab-focus();
      outline: 0;
    }
  }
}

// $opacity-gray-3:             rgba(222,222,222, .3) !default;
// $opacity-gray-5:             rgba(222,222,222, .5) !default;
// $opacity-gray-8:             rgba(222,222,222, .8) !default;


// $opacity-5:                  rgba(255,255,255, .5) !default;
// $opacity-8:                  rgba(255,255,255, .8) !default;

// $datepicker-color-days:             rgba(255,255,255, .8)  !default;
// $datepicker-color-old-new-days:     rgba(255,255,255, .4)  !default;


// $opacity-1:                  rgba(255,255,255, .1) !default;
// $opacity-2:                  rgba(255,255,255, .2) !default;


@mixin shadow-alert-color($color){
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba($color, 0.4)
}