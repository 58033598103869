.sidenav
    background-repeat: no-repeat
    background-position: center center
    background-size: cover
    background-color: #000000A6
    background-blend-mode: multiply
    position: fixed
    top: 0
    bottom: 0
    left: 0
    z-index: 2
    width: 260px
    // box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0px rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%)
    @include shadow-big()
    border-radius: 0.75rem
    overflow: hidden
    margin: 1rem 0 1rem 1rem
    .sidenav-wrapper
        color: $white
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        overflow-x: hidden
        overflow-y: auto
        padding-bottom: 25px
        .nav 
            margin-top: 15px
            display: block
        .nav-item:first-child > a
            margin: 0 15px
        .submenu
            &.collapse
                padding-top: 0
            ul.nav
                margin-top: 4px
                .nav-item
                    &:first-child .nav-link
                        margin-top: 0
                    .nav-link 
                        margin: 4px 15px 0 25px
                        i
                            font-size: 17px
                            line-height: 20px
                            width: 20px
                            margin-right: 15px
                        p
                            line-height: 20px
                            font-weight: lighter
                            font-size: 14px
        .nav-link
            color: $white
            border-radius: 0.375rem
            margin: 0.4rem 15px 0
            padding: 0.4rem
            text-transform: capitalize
            i
                font-size: 20px
                float: left
                margin-right: 15px
                line-height: 25px
                width: 30px
                text-align: center
            p
                margin: 0
                line-height: 25px
                position: relative
                display: block
                height: auto
                white-space: nowrap
                transition: all 300ms linear
                font-weight: normal
                font-size: 15px
            &:hover
                background-color: rgba(200, 200, 200, 0.2)
            &.hover-danger:hover
                background-color: $danger !important

    .logo
        padding: 20px 25px 0px 25px
        margin: 0
        display: block
        position: relative
        z-index: 4
        text-transform: uppercase
        font-size: 18px
        a.logo-link
            display: block
            white-space: nowrap
            font-weight: 400
            line-height: 30px
            overflow: hidden
            text-align: center
            color: $white
            img
                max-width: 100%
                max-height: 50px

    .user
        a
            color: $white
            border-radius: 0.375rem
            margin: 0 15px
            display: block
            padding: 0.5rem 1rem
            &:hover
                background-color: rgba(200, 200, 200, 0.2)
                text-decoration: none
        .username
            padding: 0.5rem 0px
            white-space: nowrap
            display: block
            line-height: 18px
            font-size: 14px

        .photo
            width: 34px
            height: 34px
            overflow: hidden
            float: left
            z-index: 5
            margin-right: 11px
            border-radius: 50%
            img
                width: 100%

    .caret
        display: inline-block
        width: 0
        height: 0
        margin-left: 2px
        vertical-align: middle
        border-top: 4px dashed
        border-top: 4px solid
        border-right: 4px solid transparent
        border-left: 4px solid transparent
        margin-top: 10px
        position: absolute
        right: 4px
        @include transition-all($transition-time, ease-in)
  
    hr
        background-color: rgba(180, 180, 180, 0.3)
        margin: 15px