@import "./mixins/utilities"
@import "./mixins/alerts"
@import "./mixins/buttons"
@import "./mixins/colored-shadows"
    
@mixin headings()
    h1,.h1,
    h2,.h2,
    h3,.h3,
    h4,.h4,
    h5,.h5,
    h6,.h6
        @content
    
@mixin disabled()
    fieldset[disabled][disabled] &,
    &.disabled,
    &:disabled,
    &[disabled]
        @content

@mixin transition-all($time, $type)
    -webkit-transition: all $time $type
    -moz-transition: all $time $type
    -o-transition: all $time $type
    -ms-transition: all $time $type
    transition: all $time $type

@mixin box-shadow($shadow...)
    -webkit-box-shadow: $shadow // iOS <4.3 & Android <4.1
    box-shadow: $shadow

@mixin placeholder()
    &::placeholder
        @content 
    &::-moz-placeholder // Firefox
        @content 
    &:-ms-input-placeholder // Internet Explorer 10+
        @content 
    &::-webkit-input-placeholder // Safari and Chrome
        @content 
