// Custom additions to the elfinder theme
.elfinder
    box-shadow: none !important
    .elfinder-toolbar
        border-radius: 0.375rem 0.375rem 0 0
        background-color: #f6f6f6
        .elfinder-button-icon
            color: #212529!important
        .elfinder-button
            .elfinder-button-icon
                opacity: .7
            &.ui-state-disabled
                .elfinder-button-icon
                    opacity: .4
            &.ui-state-hover
                .elfinder-button-icon
                    opacity: 1
                    cursor: pointer

        .elfinder-button-search 
            border-radius: 0.375rem
            input
                background-color: #bfc5cd
                border-radius: 0.375rem

    .elfinder-cwd
        box-sizing: border-box!important
        padding: 3px
        .elfinder-cwd-file.ui-selected.ui-state-hover, 
        .elfinder-cwd-file.ui-selected, 
        .elfinder-cwd-file.ui-state-hover
            background-color: #bfc5cd
            .elfinder-cwd-filename.ui-state-hover,
            .elfinder-cwd-filename.ui-state-active,
            .elfinder-cwd-filename
                color: #212529
        .elfinder-cwd-file
            border-radius: 0.375rem
            margin: 3px!important
    


.ui-widget-header.ui-helper-clearfix.ui-corner-bottom.elfinder-statusbar
    border-radius: 0 0 0.375rem 0.375rem
    background-color: #ffffff
    color: #212529
    border-bottom: none

.ui-state-default.elfinder-navbar.ui-resizable
    background-color: #e2e6eb

.elfinder-navbar-dir.ui-state-active.ui-state-hover, .elfinder-navbar-dir.ui-state-hover
    color: #212529 !important

.elfinder-cwd-wrapper.ui-droppable.elfinder-cwd-wrapper-l1_.native-droppable
    background-color: #f0f2f5

.elfinder .elfinder-disabled .elfinder-navbar .elfinder-navbar-dir.ui-state-active, 
.elfinder .elfinder-disabled .elfinder-navbar .elfinder-navbar-dir.ui-state-hover, 
.elfinder-navbar .elfinder-navbar-dir.ui-state-active,
.elfinder-navbar .elfinder-navbar-dir.ui-state-hover
    background-color: #bfc5cd !important
    color: #212529 !important

.elfinder .elfinder-disabled .elfinder-navbar .ui-state-active, .elfinder-navbar .elfinder-navbar-dir
    color: #212529 !important
    margin-bottom: 1px
    border-radius: 0.375rem

.elfinder .elfinder-navbar .elfinder-navbar-dir.ui-state-active .elfinder-navbar-icon,
.elfinder .elfinder-navbar .elfinder-navbar-dir.ui-state-hover .elfinder-navbar-icon,
.elfinder .elfinder-navbar-dir .elfinder-navbar-icon
    color: #212529 !important

.elfinder .ui-state-active .elfinder-navbar-arrow
    color: #212529 !important

.elfinder-path, .elfinder-stat-size
    margin: 0 !important


.elfinder-cwd-icon:before
    background-color: transparent
    color: #6f99e6

// scrollbars
.elfinder:not(.elfinder-mobile) 
    *::-webkit-scrollbar
        width: 8px
        height: 8px
    // *::-webkit-scrollbar-button
    // *::-webkit-scrollbar-corner
    *::-webkit-scrollbar-thumb
        border-radius: 10px
        background-color: #bfc5cd !important
        box-shadow: none
    *::-webkit-scrollbar-track
        border-radius: 0
        box-shadow: none