.wrapper
    position: relative
    top: 0
    height: 100vh
    &:after
        display: table
        clear: both
        content: " "
    @include media-breakpoint-down(sm)
        display: none

@import "./components/_main-panel"
@import "./components/_sidebar"


// RESPONSIVE
.mobile-menu-button
    display: none
@include media-breakpoint-down(lg)
    .sidenav
        bottom: auto
        right: 0
        width: auto
        border-radius: 0
        margin: 0
        height: 65px
        overflow: visible
        z-index: 999
        .sidenav-wrapper
            padding: 0
            overflow: visible
            .logo
                padding: .5rem
                height: 100%
                width: 200px
                display: flex
                justify-content: start
                align-items: center
                .logo-link
                    width: 100%
            .user
                display: none
            > .nav
                // display: none
                margin: 0
                padding: 1rem 0
                background-color: #000
                transform: scaleY(0)
                transform-origin: top
                transition: transform 0.3s ease-in-out
                z-index: -1
                &.show-mobile-menu
                    // display: block
                    transform: scaleY(1)
            .mobile-menu-button
                display: block
                position: absolute
                top: 0
                right: 0
                background: transparent
                color: #FFF
                border: none
                height: 100%
                aspect-ratio: 1
                font-size: 25px
            hr
                display: none
        

    .main-panel
        margin-top: 65px
        padding: 15px 5px
        width: 100%
        > .content
            min-height: calc(100vh - 165px)

        .breadcrumb
            display: none