.tooltip
    display: none
    font-size: $tooltip-font-size
    &.show
        display: block

    .arrow::before
        border-top-color: rgba(0, 0, 0, 0.85)

    .tooltip-inner
        background-color: rgba(0, 0, 0, 0.85)
        padding: 5px 12px
        border-radius: 0.375rem