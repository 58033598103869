@mixin shadow-big(){
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, $crob-shadow-penumbra-opacity * 4),
  0  4px 25px 0px rgba(0, 0, 0, $crob-shadow-ambient-opacity),
  0  8px 10px -5px rgba(0, 0, 0, $crob-shadow-umbra-opacity);
}

@mixin shadow-small-color($color){
  // new box shadow optimized for Tablets and Phones
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, .14),
  0 7px 10px -5px rgba($color, 0.4)
}

@mixin shadow-1dp(){
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, $crob-shadow-penumbra-opacity),
  // 0 3px 1px -2px rgba(0, 0, 0, $crob-shadow-ambient-opacity),
  0 1px 3px 0 rgba(0, 0, 0, $crob-shadow-umbra-opacity);
}

@mixin shadow-2dp(){
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, $crob-shadow-penumbra-opacity),
  0 3px 1px -2px rgba(0, 0, 0, $crob-shadow-ambient-opacity),
  0 1px 5px 0 rgba(0, 0, 0, $crob-shadow-umbra-opacity);
}

@mixin shadow-4dp(){
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, $crob-shadow-penumbra-opacity),
  0 1px 10px 0 rgba(0, 0, 0, $crob-shadow-ambient-opacity),
  0 2px 4px -1px rgba(0, 0, 0, $crob-shadow-umbra-opacity);
}

@mixin shadow-2dp-color($color){
  box-shadow: 0 2px 2px 0 rgba($color, $crob-shadow-penumbra-opacity),
  0 3px 1px -2px rgba($color, $crob-shadow-umbra-opacity),
  0 1px 5px 0 rgba($color, $crob-shadow-ambient-opacity);
}

@mixin shadow-4dp-color($color){
  box-shadow: 0 4px 5px 0 rgba($color, $crob-shadow-penumbra-opacity),
  0 1px 10px 0 rgba($color, $crob-shadow-ambient-opacity),
  0 2px 4px -1px rgba($color, $crob-shadow-umbra-opacity);
}