.slide-builder
    tr th
        color: #aaa
        font-size: 0.9em !important
        padding: 5px !important
        line-height: 1
    td
        padding: 5px !important
    &__handle
        text-align: center
        color: #FFF
        width: 25px
        transition: all .3s ease
        cursor: grab
        // &:hover
        //     width: 35px
    &__delete
        text-align: center
        color: #FFF !important
        width: 25px
        transition: all .3s ease
        cursor: pointer
        background-color: $danger !important
        // &:hover
        //     width: 35px
    &__button
        width: 128px
        height: 72px
        padding: 0
        background-color: #E9EEF1
        border: 1px solid rgba(170, 170, 170, 0.5)
    &__image
        max-width: 100%
        max-height: 100%
    textarea.form-control
        height: 72px!important
        background: #fff
        border-bottom: 1px solid rgba(170, 170, 170, 0.5)