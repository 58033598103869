.dd-list
  display: block
  position: relative
  margin: 0
  padding: 0
  list-style: none
  .dd-item
    margin: 5px 0
    border-radius: 0.375rem
    // overflow: hidden
    position: relative
    > .dd-list
      padding-left: 30px
    > button
      display: none

    .dd-handle
      position: absolute
      height: 32px
      width: 32px
      left: 0
      top: 0
      padding: 5px
      color: $white
      font-weight: 700
      cursor: grab
      overflow: hidden
      background: #00B0CA
      text-align: center
      border-radius: 0.375rem 0 0 0.375rem

    .dd-content
      display: block
      padding: 3px 10px 3px 40px
      color: #333
      text-decoration: none
      font-weight: 400
      border: 1px solid #eee
      background: #fafafa
      border-radius: 0.375rem
      box-sizing: border-box

      .dd-content__header
        cursor: pointer
        i
          position: absolute
          right: 10px
          top: 9px
          transition: all .3s ease
          transform: rotate(90deg)
        &.collapsed i
          transform: rotate(0)

      .collapse::before,
      .collapsing::before
        content: ''
        padding-top: 10px
        display: block


.dd-dragel
  position: absolute
  pointer-events: none
  z-index: 9999

  > .dd-item .dd-handle
    margin-top: 0

  .dd-handle
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1)

.dd-empty, .dd-placeholder
  margin: 5px 0
  padding: 0
  min-height: 30px
  background: #f2fbff
  border: 1px dashed #b6bcbf
  box-sizing: border-box
  -moz-box-sizing: border-box