.gallery
    .gallery__grid
        @extend .clearfix
        width: 100%
        background-color: #F9F9F9
        padding: 5px
        .gallery__grid__item
            height: 100px
            width: 100px
            float: left
            margin: 10px
            position: relative
            background-color: #E9EEF1
            border-radius: 0.375rem
            overflow: hidden
            .gallery-item-button
                position: absolute
                width: 20px
                height: 20px
                display: flex
                justify-content: center
                align-items: center
                color: #FFF
            .gallery-item-handle
                @extend .gallery-item-button
                top: 0
                left: 0
                background-color: #00b0ca
                cursor: grab
            .gallery-item-remove
                @extend .gallery-item-button
                top: 0
                right: 0
                background-color: $danger
                cursor: pointer


            .gallery-item-image
                border-radius: 0.375rem
                width: 100%
                height: 100%
                border: none
                background-color: transparent
                .gallery-item-image-thumbnail
                    max-width: 100%
                    max-height: 100%
