.list
    .list-row
        border-radius: 0.375rem
        background-color: #fafafa
        padding: 3px 45px
        border: 1px solid #eee
        overflow: hidden
        position: relative
        margin: 5px 0
        .list-row__drag
            position: absolute
            background-color: #00B0CA
            color: white
            width: 25px
            left: 0
            top: 0
            bottom: 0
            text-align: center
            transition: all .3s ease
            cursor: grab
            i
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%,-50%)
            &:hover
                width: 35px

        .list-row__delete
            position: absolute
            background-color: $danger
            color: white
            width: 25px
            right: 0
            top: 0
            bottom: 0
            text-align: center
            transition: all .3s ease
            cursor: pointer
            i
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%,-50%)
            &:hover
                width: 35px

        