// Scrollbar styling
html
  overflow-y: overlay


::-webkit-scrollbar 
  width: 8px
  height: 8px

::-webkit-scrollbar-track 
  background: transparent

::-webkit-scrollbar-thumb 
  background: #888
  border-radius: 4px

::-webkit-scrollbar-thumb:hover 
  background: #555

html,body
  &::-webkit-scrollbar 
    width: 12px
    height: 12px

  &::-webkit-scrollbar-track 
    background: transparent

  &::-webkit-scrollbar-thumb 
    background: #888
    border-radius: 6px
    border: 3px solid transparent
    &:hover
      background: #555

// Firefox support
html
  scrollbar-color: #888 transparent
  scrollbar-width: thin