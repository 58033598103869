// BS5 colors
$green: #28a745
$danger: #dc3545
    
// CROB colors
$crob-blue: #00B0CA
$crob-rose: #E21876
$crob-grey: #999999
$crob-black: #3C4858

// typography
$font-size-h1: 3.3125rem
$font-size-h2: 2.25rem
$font-size-h3: 1.5625rem
$font-size-h4: 1.125rem
$font-size-h5: 1.0625rem
$font-size-h6: 0.75rem

// shadows
$crob-shadow-umbra-opacity: 0.2
$crob-shadow-penumbra-opacity: 0.14
$crob-shadow-ambient-opacity: 0.12

// $crob-shadow-sm: 0 2px 2px 0 rgba($crob-black, $crob-shadow-penumbra-opacity), 0 3px 1px -2px rgba($crob-black, $crob-shadow-umbra-opacity), 0 1px 5px 0 rgba($crob-black, $crob-shadow-ambient-opacity)

// border-radius
$crob-radius-small: 0.375rem
$crob-radius-medium: 0.75rem
$crob-radius-large: 1.5rem

// misc
$transition-time: 150ms

$table-striped-bg: rgba($crob-black, .03)