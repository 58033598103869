.chart__holder
  height: 300px

.chart__figure
  height: 75%

.chart__legend
  height: 25%
  list-style: none
  margin: 0
  padding: 1rem 0 0
  text-align: center
  li
    display: inline-block
    padding: 0.25rem 0.5rem
    i 
      display: inline-block
      height: 1em
      margin-right: .5em
      vertical-align: -0.1em
      width: 1em