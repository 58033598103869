.fileinput .thumbnail
    background-color: #ebebeb
    border-radius: 0.75rem
    box-shadow: 0 5px 15px -8px rgb(150 150 150 / 24%), 0 8px 5px -5px rgb(120 120 120 / 20%)
    margin-bottom: 10px
    overflow: hidden
    text-align: center
    vertical-align: middle
    display: flex
    justify-content: center
    align-items: center

.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists 
    display: none!important
