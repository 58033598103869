.header-filter
    position: relative

    &:before,
    &:after
        position: absolute
        z-index: 1
        width: 100%
        height: 100%
        display: block
        left: 0
        top: 0
        content: ""

    &::before
        background: rgba(0,0,0,.2)

    .container
        z-index: 2
        position: relative