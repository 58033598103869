.google-information
  &__header
    margin-bottom: 0
    color: #1a0dab
    font-size: 20px
    font-weight: 400
    line-height: 26px
  &__link
    color: #006621
    font-size: 16px
    font-weight: 400
  &__description
    color: #545454
    font-size: 14px
    font-weight: 400