@mixin alert-variations($component, $selector-suffix, $brand-default) {
  @include generic-variations($component, $selector-suffix, $brand-default, "alert-variations-content", null);
}

@mixin alert-variations-content($args){
  $variation-color: map-get($args, variation-color);
  $variation-color-text: map-get($args, variation-color-text);

  background-color: lighten($variation-color,3%);
  color: $variation-color-text;

  a, .alert-link {
    color: $variation-color-text;
  }
}

// interpolation of mixin-name is not allowed evidently, so we statically include based on the mixin-name given
@mixin call-variations-content-mixin($args) {
  $mixin-name: map-get($args, mixin-name);
  @if $mixin-name == variations-content {
    @include variations-content($args);
  } @else if $mixin-name == background-variations-content {
    @include background-variations-content($args);
  } @else if $mixin-name == text-variations-content {
    @include text-variations-content($args);
  } @else if $mixin-name == button-variations-content {
    @include button-variations-content($args);
  } @else if $mixin-name == bg-color-variations-content {
    @include bg-color-variations-content($args);
  } @else if $mixin-name == bg-box-shadow-variations-content {
    @include bg-box-shadow-variations-content($args);
  } @else if $mixin-name == bg-img-variations-content {
    @include bg-img-variations-content($args);
  } @else if $mixin-name == navbar-variations-content {
    @include navbar-variations-content($args);
  }@else if $mixin-name == alert-variations-content {
    @include alert-variations-content($args);
  } @else {
    @error "Unknown mixin: #{$mixin-name}";
  }
}

@mixin generic-variations($component, $selector-suffix, $color-default, $mixin-name, $mdb-param-1) {

  //setup map to pass parameters (instead of the incredibly long-error-prone list for each and every @include)
  $args: (
          //extra: $selector-suffix,
          //default: $color-default,
          mixin-name: $mixin-name,
          material-param-1: $mdb-param-1
  );

  // bootstrap styles
  &#{$selector-suffix},
  &#{$component}-default#{$selector-suffix} {

    $args-extra: map-merge($args, (
            variation-color: $white,
            variation-color-text: $crob-grey
    ));
    @include call-variations-content-mixin($args-extra);
  }
  &#{$component}-inverse#{$selector-suffix} {
    $args-inverse: map-merge($args, (
            variation-color: #212121,
            variation-color-text: #fff
    ));
    @include call-variations-content-mixin($args-inverse);
  }
  &#{$component}-primary#{$selector-suffix} {
    $args-primary: map-merge($args, (
            variation-color: $primary,
            variation-color-text: $white
    ));
    @include call-variations-content-mixin($args-primary);
  }
  &#{$component}-success#{$selector-suffix} {
    $args-success: map-merge($args, (
            variation-color: $success,
            variation-color-text: $white
    ));
    @include call-variations-content-mixin($args-success);
  }
  &#{$component}-info#{$selector-suffix} {
    $args-info: map-merge($args, (
            variation-color: $crob-blue,
            variation-color-text: $white
    ));
    @include call-variations-content-mixin($args-info);
  }
  &#{$component}-warning#{$selector-suffix} {
    $args-warning: map-merge($args, (
            variation-color: $warning,
            variation-color-text: $white
    ));
    @include call-variations-content-mixin($args-warning);
  }
  &#{$component}-danger#{$selector-suffix} {
    $args-danger: map-merge($args, (
            variation-color: $danger,
            variation-color-text: $white
    ));
    @include call-variations-content-mixin($args-danger);
  }

  &#{$component}-rose#{$selector-suffix} {
    $args-rose: map-merge($args, (
            variation-color: $crob-rose,
            variation-color-text: $white
    ));
    @include call-variations-content-mixin($args-rose);
  }

}

@mixin alert-icon-color($color) {
  i{
    color: $color;
  }
}
