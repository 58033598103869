@import 'choices.js/public/assets/styles/choices.min.css'

.choices
    font-weight: 400
    width: 100%
    .choices__inner
        background-color: transparent
        display: flex
        flex-wrap: wrap
        align-items: center
        padding: 0.375rem 0
        border: none
        min-height: 41px
        font-size: 1rem
    .choices__list
        background: no-repeat bottom,50% calc(100% - 1px)
        background-size: 0 100%,100% 100%
        transition: .2s ease
        &--single
            // background-image: linear-gradient(0deg,#e91e63 2px,rgba(156,39,176,0) 0),linear-gradient(0deg,#d2d2d2 1px,hsla(0,0%,82%,0) 0)
            padding: 0
            min-height: 35px
            .choices__item
                line-height: 2.2
        &--dropdown
            background-color: $white
            border-radius: $crob-radius-small
            border: none
            @include shadow-1dp()
    &[data-type*=select-multiple] .choices__input,
    &[data-type*=text] .choices__input
        background: no-repeat bottom,50% calc(100% - 1px)
        background-size: 0 100%,100% 100%
        transition: .2s ease
        background-color: transparent
        min-height: 35px
        flex-grow: 1

.choices.is-focused .choices__list,
.choices.is-focused .choices__input
    background-size: 100% 100%,100% 100%

.choices__list--dropdown .choices__item, .choices__list[aria-expanded] .choices__item
    padding: 0.5rem!important