.footer
    padding: 0.9375rem 0
    text-align: center
    display: -webkit-flex
    display: flex

    ul
        margin-bottom: 0
        padding: 0
        list-style: none

        li
            display: inline-block

            a
                color: inherit
                padding: 0.9375rem
                font-weight: $font-weight-bold
                font-size: 12px
                text-transform: uppercase
                border-radius: 3px
                text-decoration: none
                position: relative
                display: block

                &:hover
                    text-decoration: none

            .btn
                margin: 0
            
        &.links-horizontal
            &:first-child a
                padding-left: 0

            &:last-child a
                padding-right: 0

        &.links-vertical
            li
                display: block
                margin-left: -5px
                margin-right: -5px

                a
                    padding: 5px

    .social-buttons
        a,
        .btn
            margin-top: 5px
            margin-bottom: 5px    

    .footer-brand
        float: left
        height: 50px
        padding: 15px 15px
        font-size: 18px
        line-height: 20px
        margin-left: -15px

        &:hover,
        &:focus
            color: $crob-black

    .pull-center
        display: inline-block
        float: none

.off-canvas-sidebar
    .footer
        position: absolute
        bottom: 0
        width: 100%