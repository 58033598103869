.accordion
    .accordion-row
        margin: 5px 0
        border-radius: 0.375rem
        position: relative
        &__drag
            transition: all .3s ease
            position: absolute
            height: 32px
            width: 25px
            left: 0
            top: 0
            padding: 5px
            color: $white
            font-weight: 700
            cursor: grab
            overflow: hidden
            background: #00B0CA
            text-align: center
            border-radius: 0.375rem 0 0 0.375rem
            &:hover
                width: 35px
        &__content
            display: block
            padding: 3px 10px 3px 40px
            color: #333
            text-decoration: none
            font-weight: 400
            border: 1px solid #eee
            background: #fafafa
            border-radius: 0.375rem
            box-sizing: border-box

            &__header
                cursor: pointer
                i
                    position: absolute
                    right: 10px
                    top: 9px
                    transition: all .3s ease
                    transform: rotate(90deg)
                &.collapsed i
                    transform: rotate(0)
            
            .collapse::before,
            .collapsing::before
                content: ''
                padding-top: 10px
                display: block
