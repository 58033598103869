.alert
    border: 0
    border-radius: 0.75rem
    position: relative
    padding: 20px 15px
    line-height: 20px

    b
        font-weight: $font-weight-bold
        text-transform: uppercase
        font-size: 12px

    // SASS conversion note: please mirror any content change in _mixins-shared.scss alert-variations-content
    @include alert-variations(unquote(".alert"), unquote(""), $white)

    &-info, &-danger, &-warning, &-success, &-rose
        color: $white

    &-default 
        a, .alert-link 
            color: $black

    span
        display: block
        max-width: 89%

    &.alert-danger
        @include shadow-alert-color($danger)
        @include alert-icon-color($danger)
    &.alert-warning
        @include shadow-alert-color($warning)
        @include alert-icon-color($warning)
    &.alert-success
        @include shadow-alert-color($success)
        @include alert-icon-color($success)
    &.alert-info
        @include shadow-alert-color($crob-blue)
        @include alert-icon-color($crob-blue)
    &.alert-primary
        @include shadow-alert-color($primary)
        @include alert-icon-color($primary)
    &.alert-rose
        @include shadow-alert-color($crob-rose)
        @include alert-icon-color($crob-rose)

    &.alert-with-icon
        margin-top: 43px
        padding-left: 66px

        i[data-notify="icon"] 
            display: block
            left: 15px
            position: absolute
            margin-top: -39px
            font-size: 20px
            background-color: $white
            padding: 9px
            border-radius: 50%
            max-width: 38px
            @include shadow-big()

    .close
        line-height: .5
        i
            color: $white
            font-size: 11px
            
        &:focus 
            outline: none

    i[data-notify="icon"]
        display: none

    .alert-icon
        display: block
        float: left
        margin-right: 1.071rem

        i
            margin-top: -7px
            top: 5px
            position: relative

    [data-notify="dismiss"]
        margin-right: 5px