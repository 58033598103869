.table
    margin: 0
    thead
        tr
            th
                font-size: 1.065rem
                font-weight: $font-weight-light

    > thead > tr > th,
    > tbody > tr > th,
    > tfoot > tr > th,
    > thead > tr > td,
    > tbody > tr > td,
    > tfoot > tr > td
        padding: 5px
        vertical-align: middle


    // .td-actions
    form
        display: inline-block
    .btn
        height: 30px
        min-width: 30px
        padding: 0.4rem
        // aspect-ratio: 1
        display: inline-flex
        justify-content: center
        align-items: center
        margin: 0 0 0 0.2rem
        font-size: 1em
        border-radius: $crob-radius-small

    .table-col-fit-content
        white-space: nowrap
        width: 1%
    .td-status
        i
            width: 15px
            display: inline-flex !important
            justify-content: center
            align-items: center
    .td-actions
        padding-left: 1rem
        white-space: nowrap
        width: 1%

#datatables_wrapper
    .dt-length
        select
            // @extend .form-control
            width: auto
            display: inline-block
            margin-right: 10px
    .dt-search
        label
            display: none

    .dataTable
        &.collapsed
            .dt-collapse-head
                pointer-events: none
                &::before
                    display: none!important
            .dtr-control
                padding-left: 25px!important
                position: relative
                cursor: pointer
                &::before
                    content: '+'
                    background-color: transparent!important
                    border: 1px solid
                    width: 15px
                    height: 15px
                    position: absolute
                    right: 5px
                    top: 50%
                    transform: translateY(-50%)
                    display: flex
                    justify-content: center
                    align-items: center
                    text-align: center
                    border-radius: 50%
                    opacity: .5
                &:hover
                    &::before
                        opacity: 1
            .dtr-expanded .dtr-control::before
                content: '-'

            .dtr-details
                display: inline-block
                list-style-type: none
                margin: 0
                padding: 0
                li
                    display: flex
                    border-bottom: 1px solid #efefef
                    padding: 0.5rem 0
                    &:first-child
                        padding-top: 0
                    .dtr-title
                        display: inline-block
                        font-weight: 700
                        min-width: 100px
                    .dtr-data
                        padding-left: 1rem
                        display: inline-block
                        // form
                        //     display: inline-block
                        // .btn
                        //     padding: 7px
                        //     margin: 0 0 0 3px
                        //     width: 35px
                        //     font-size: 1em



.table-borderless
    border: none!important
    tr
        border: none!important
        th,
        td
            border: none!important