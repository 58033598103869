.form-control.bootstrap-select
    padding-bottom: 41px
    > .dropdown-toggle
        padding-right: 10px
    .dropdown-toggle 
        &::after
            will-change: transform
            transition: transform 150ms linear
            display: inline-block
            width: 0
            height: 0
            margin-left: 0.255em
            vertical-align: 0.255em
            content: ""
            border-top: 0.3em solid black
            border-right: 0.3em solid transparent
            border-bottom: 0
            border-left: 0.3em solid transparent
        .filter-option
            padding-left: 0
    .dropdown-item
        font-size: 1rem
    button
        border-radius: 0
        cursor: pointer
        position: relative
        padding: 12px 30px
        margin: 0.3125rem 1px
        font-size: 0.75rem
        font-weight: 400
        line-height: 1.42857143
        text-decoration: none
        text-transform: uppercase
        letter-spacing: 0
        background-color: transparent!important
        border: 0
        outline: 0
        box-shadow: none!important
        &:focus
            outline: none !important
            
.form-col select
    display: block
    width: 100%
    border: none
    border-bottom: 1px solid rgba(170, 170, 170, 0.5)
    padding: .5rem 0
    margin-bottom: 0.5rem

select, select.form-control
    -webkit-appearance: auto

.dropdown
    margin-top: -2px

.show > .dropdown-toggle:not(.dropdown-item):after
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2)
    transform: rotate(180deg)
